import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/solid';

const CallToAction = () => {
  return (
    <div className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
          <span className="block">Ready to start discovering meals?</span>
          <span className="block text-purple-300">
            Join ChowBuddy on Telegram today.
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <a
              href="https://t.me/ChowBuddyBot"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-purple-600 bg-white hover:bg-purple-50 transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              Start Chatting
              <ArrowRightIcon
                className="ml-3 -mr-1 h-5 w-5"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
