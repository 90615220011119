import React, { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/solid';

const UserOnboardingGuide = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const accordionData = [
    {
      title: 'Getting Started with ChowBuddy',
      content: (
        <p>
          On Telegram, search for <strong>ChowBuddyBot</strong> and press
          'Start'.
        </p>
      )
    },
    {
      title: 'Main Menu',
      content: (
        <>
          <p>You'll see a welcome message and the following options:</p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>Get a recipe with my ingredients.</li>
            <li>Inspire me with a random quick meal.</li>
            <li>Quick meal inspired by a country.</li>
          </ul>
        </>
      )
    },
    {
      title: 'Choosing an Option',
      content: (
        <p>
          Select one of the options to begin your culinary journey with
          ChowBuddy.
        </p>
      )
    },
    {
      title: 'Feature Walkthrough',
      content: (
        <ul className="space-y-2">
          <li>
            <strong>Get a Recipe:</strong> Tell ChowBuddy what ingredients you
            have, and it will suggest a custom recipe.
          </li>
          <li>
            <strong>Random Quick Meal:</strong> ChowBuddy will suggest a meal
            that can be prepared in 30 minutes or less.
          </li>
          <li>
            <strong>Inspired by a Country:</strong> Name a country, and
            ChowBuddy will suggest a dish from that region.
          </li>
        </ul>
      )
    },
    {
      title: 'Follow-Up Actions',
      content: (
        <ul className="space-y-2">
          <li>
            <strong>Another Quick Meal:</strong> If you're not satisfied with
            the suggestion, you can ask ChowBuddy for another option.
          </li>
          <li>
            <strong>Return to Main Menu:</strong> At any point, you can return
            to the main menu to explore other options.
          </li>
        </ul>
      )
    }
  ];

  useEffect(() => {
    console.log('Accordion content debug:', accordionData);
  }, []);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="py-12 bg-gray-50 dark:bg-gray-900 transition-colors duration-300">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800 dark:text-gray-100">
          How to Use ChowBuddy
        </h2>
        <div className="max-w-3xl mx-auto">
          {accordionData.map((item, index) => (
            <div
              key={index}
              className="border border-gray-200 dark:border-gray-700 rounded-lg mb-4 overflow-hidden transition-colors duration-300"
            >
              <button
                onClick={() => handleToggle(index)}
                className="w-full text-left p-4 font-semibold bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300 flex justify-between items-center"
                aria-expanded={openIndex === index}
              >
                <span className="text-gray-800 dark:text-gray-200">
                  {item.title}
                </span>
                <ChevronDownIcon
                  className={`h-5 w-5 text-gray-500 dark:text-gray-400 transform transition-transform duration-300 ${
                    openIndex === index ? 'rotate-180' : ''
                  }`}
                />
              </button>
              <div
                className={`transition-all duration-300 ${
                  openIndex === index ? 'max-h-96' : 'max-h-0'
                } overflow-hidden`}
              >
                <div className="p-4 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300">
                  {item.content}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-8">
          <a
            href="https://t.me/ChowBuddyBot"
            className="bg-purple-500 text-white px-6 py-3 rounded-full font-semibold text-lg hover:bg-purple-600 transition duration-300 inline-block"
            target="_blank"
            rel="noopener noreferrer"
          >
            Start Chatting Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default UserOnboardingGuide;
