import React from 'react';

import {
  LightningBoltIcon,
  GlobeIcon,
  SparklesIcon
} from '@heroicons/react/outline';

const FeatureCard = ({ icon: Icon, title, description }) => {
  return (
    <div className="relative bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl">
      <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-purple-500 to-indigo-500"></div>
      <div className="text-purple-500 mb-4">
        <Icon className="h-8 w-8" />
      </div>
      <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-white">
        {title}
      </h3>
      <p className="text-gray-600 dark:text-gray-300">{description}</p>
    </div>
  );
};

const FeaturesSection = () => {
  const features = [
    {
      icon: LightningBoltIcon,
      title: 'Quick Recipes',
      description:
        'Find quick and easy recipes to cook within minutes based on the ingredients you have at home.'
    },
    {
      icon: SparklesIcon,
      title: 'Meal Inspiration',
      description:
        'Get healthy meal suggestions that taste great and fit your dietary preferences.'
    },
    {
      icon: GlobeIcon,
      title: 'Cultural Cuisines',
      description:
        'Explore meals from different cultures and regions to expand your culinary horizons.'
    }
  ];

  return (
    <section
      id="features"
      className="bg-gray-50 dark:bg-gray-900 py-20 transition-colors duration-300"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
            Why Choose ChowBuddy?
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-300">
            Discover the features that make ChowBuddy your perfect cooking
            companion.
          </p>
        </div>
        <div className="mt-20 grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
