import React from 'react';
import {
  ArrowRightIcon,
  SparklesIcon,
  TrashIcon
} from '@heroicons/react/outline';

const FeatureHighlight = ({ icon: Icon, text }) => (
  <div className="flex items-center mb-4 animate-fadeIn">
    <div className="bg-white bg-opacity-20 p-2 rounded-full mr-3">
      <Icon className="h-6 w-6 text-yellow-300" />
    </div>
    <span className="text-lg">{text}</span>
  </div>
);

const HeroSection = () => {
  return (
    <div className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-24 dark:from-purple-800 dark:to-indigo-800 relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute left-1/3 top-1/4 w-64 h-64 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob"></div>
        <div className="absolute right-1/3 bottom-1/4 w-64 h-64 bg-pink-300 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-2000"></div>
        <div className="absolute left-1/2 bottom-1/2 w-64 h-64 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-4000"></div>
      </div>
      <div className="container mx-auto px-4 relative z-10">
        <div className="md:flex md:items-center md:justify-between">
          <div className="md:w-1/2 animate-slideInLeft">
            <h1 className="text-5xl font-bold mb-6 dark:text-gray-100 leading-tight">
              Your AI-Powered <br />
              <span className="text-yellow-300">Recipe Assistant</span>
            </h1>
            <FeatureHighlight
              icon={SparklesIcon}
              text="Discover delicious global cuisines"
            />
            <FeatureHighlight
              icon={TrashIcon}
              text="Reduce food waste with ingredient-based recipes"
            />
            <p className="text-xl mb-8 dark:text-gray-200">
              Create mouthwatering meals from your own ingredients and expand
              your culinary horizons!
            </p>
            <div className="space-x-4">
              <a
                href="https://t.me/ChowBuddyBot"
                className="bg-white text-purple-600 px-8 py-4 rounded-full font-semibold hover:bg-yellow-300 hover:text-purple-700 transition duration-300 inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Start Cooking <ArrowRightIcon className="ml-2 h-5 w-5" />
              </a>
              <a
                href="#features"
                className="text-white underline hover:text-yellow-300 transition duration-300 font-medium"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className="md:w-1/2 mt-12 md:mt-0 animate-slideInRight">
            <div className="bg-white bg-opacity-10 rounded-lg p-8 text-center backdrop-filter backdrop-blur-lg shadow-xl relative overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-br from-purple-400 to-indigo-400 opacity-30 transform rotate-12 scale-150"></div>
              <div className="relative">
                <div className="text-7xl mb-6 animate-bounce">🍳</div>
                <p className="text-3xl font-bold mb-4 dark:text-gray-100">
                  Your personal chef is just a chat away!
                </p>
                <p className="text-xl dark:text-gray-200">
                  Get personalised recipes and reduce food waste simultaneously.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
