import React from 'react';

const TestimonialCard = ({ quote, name, title }) => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-colors duration-300">
      <p className="text-gray-600 dark:text-gray-300 mb-4">"{quote}"</p>
      <div>
        <p className="font-semibold text-gray-800 dark:text-white">{name}</p>
        <p className="text-gray-500 dark:text-gray-400">{title}</p>
      </div>
    </div>
  );
};

const TestimonialsSection = () => {
  const testimonials = [
    {
      quote:
        "ChowBuddy has transformed the way I cook at home. It's like having a personal chef in my pocket!",
      name: 'Jamie L.',
      title: 'Software Engineer'
    },
    {
      quote:
        "The recipe suggestions are spot on and super easy to follow. I've never felt more inspired in the kitchen!",
      name: 'Alex D.',
      title: 'Busy Professional'
    },
    {
      quote:
        'I love the variety of meals I can create with just the ingredients I have. No more food waste!',
      name: 'Ovos A.',
      title: 'Medical Doctor'
    }
  ];

  return (
    <div className="bg-gray-200 dark:bg-gray-800 py-16 transition-colors duration-300">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center text-gray-800 dark:text-white mb-8">
          What Our Users Say
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
